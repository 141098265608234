<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    overlay-opacity="0.9"
    @click:outside="close"
    :persistent="loading"
  >
    <v-card v-if="!refoundInfo" class="pa-4">
      <div class="d-flex align-center justify-center pa-8">
        <v-progress-circular v-if="loading" indeterminate color="primary" />
      </div>
      <v-alert v-if="error" type="error" class="mb-0">
        {{ error }}
      </v-alert>
      <v-card-actions>
        <v-btn color="primary" text @click="close" block class="ml-0">
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
    <template v-else>
      <v-card v-if="['error', 'success', 'pending'].includes(currStatus?.type)">
        <v-card-text>
          <div class="text-center pt-8">
            <v-avatar
              class="mb-4"
              size="60"
              :color="`${
                currStatus?.type == 'pending' ? 'warning' : currStatus?.type
              } lighten-4`"
            >
              <v-icon
                x-large
                :color="`${
                  currStatus?.type == 'pending' ? 'warning' : currStatus?.type
                } darken-4`"
              >
                {{
                  currStatus?.type == "pending"
                    ? "mdi-cash-clock"
                    : currStatus?.type == "error"
                    ? "mdi-alert"
                    : "mdi-check"
                }}
              </v-icon>
            </v-avatar>
            <h3 class="mb-4">{{ currStatus.title }}</h3>
            <p>{{ currStatus.message }}</p>
            <v-card v-if="refoundInfo?.refoundRequest?.answer" outlined class="pa-3 text-justify mt-4">
              <p class="font-weight-bold">Resposta:</p>

              <p
                class="mb-2"
                v-for="(line, i) in refoundInfo.refoundRequest.answer.split(
                  '\n'
                )"
                :key="i"
              >
                {{ line }}
              </p>
            </v-card>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex flex-column">
          <v-btn color="primary" text @click="close" block class="ml-0">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="currStatus.type == 'canRefound'">
        <v-card-title>Solicitar Reembolso</v-card-title>
        <v-card-text>
          <v-form v-model="valid" @submit.stop>
            <v-textarea
              v-model="reason"
              label="Qual motivo do reembolso?"
              :disabled="loading"
              outlined
              :rules="[
                (v) => !!v || 'Por favor, informe o motivo do reembolso',
                (v) =>
                  v.length <= 200 ||
                  'O motivo do reembolso deve ter no máximo 200 caracteres',
                (v) =>
                  v.length >= 10 || 'Descreva melhor o motivo do reembolso',
                (v) =>
                  (v && v.split(' ').length >= 3) ||
                  'Descreva melhor o motivo do reembolso',
              ]"
              :rows="3"
              :counter="200"
              placeholder="Descreva o motivo do reembolso"
            />
            <v-alert type="info" text>
              <b>
                A solicitação de reembolso será enviada para a organização do
                evento,
              </b>
              que irá analisar e entrar em contato com você. Quando o seu
              pagamento for reembolsado <b>avisaremos você por WhatsApp</b>.
            </v-alert>
            <v-checkbox
              v-model="refoundConfirm"
              :disabled="loading"
              :rules="[(v) => v || 'Confirme que deseja o reembolso']"
              label="Confirmo que desejo solicitar o reembolso e que não será possível reverter essa ação."
            />
          </v-form>
          <v-alert v-if="error" type="error" class="mb-0">
            {{ error }}
          </v-alert>
        </v-card-text>
        <v-card-actions class="d-flex flex-column">
          <v-btn
            color="warning"
            :disabled="!valid"
            @click="requestRefound"
            block
            class="ml-0"
            :loading="loading"
          >
            Solicitar Reembolso
          </v-btn>
          <v-btn
            color="primary"
            :disabled="loading"
            text
            @click="close"
            block
            class="ml-0"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import TICKET from "@/services/app/ticket";
import { mapGetters } from "vuex";
import PhoneInput from "../../global/PhoneInput.vue";
export default {
  components: { PhoneInput },
  data: () => ({
    dialog: false,
    loading: false,
    error: false,

    ticket: null,
    refoundInfo: null,

    valid: false,
    reason: "",
    refoundConfirm: false,

    statusDic: {
      PARTY_EXPIRED: {
        type: "error",
        title: "Não é possível realizar o reembolso",
        message:
          "Esse evento já ocorreu e não é mais possível realizar o reembolso.",
      },
      TRANSFERRED: {
        type: "error",
        title: "Não é possível realizar o reembolso",
        message:
          "Esse ingresso foi transferido de outra pessoa, solicite que ela entre em contato com a organização do evento.",
      },
      COURTESY: {
        type: "error",
        title: "Não é possível realizar o reembolso",
        message:
          "Esse ingresso foi uma cortesia, não é possível realizar o reembolso.",
      },
      CAN_REFUND_OFFLINE: {
        type: "canRefound",
      },
      CAN_REFUND: {
        type: "canRefound",
      },
      REFOUND_REFUSED: {
        type: "error",
        title: "Reembolso recusado",
        message:
          "A organização do evento recusou o seu pedido de reembolso, entre em contato com eles para mais informações.",
      },
      REFUND_APPROVED: {
        type: "success",
        title: "Reembolso aprovado",
        message: "A organização do evento aprovou o seu pedido de reembolso.",
      },
      REFUND_REQUESTED: {
        type: "pending",
        title: "Reembolso solicitado",
        message:
          "A organização do evento está analisando o seu pedido de reembolso.",
      },
      CHECKED_IN: {
        type: "error",
        title: "Não é possível realizar o reembolso",
        message: "Esse ingresso já foi utilizado para entrar no evento.",
      },
      NOT_PAYED: {
        type: "error",
        title: "Não é possível realizar o reembolso",
        message:
          "Esse ingresso não está pago, não é possível realizar o reembolso.",
      },
    },
  }),
  methods: {
    async getRefoundInfo(ticketId) {
      try {
        this.loading = true;
        this.error = false;
        const refoundInfo = await TICKET.refoundInfo(ticketId);

        this.refoundInfo = refoundInfo;
      } catch (e) {
        this.error = e.message || "Erro ao carregar informações do ingresso";
      } finally {
        this.loading = false;
      }
    },
    async requestRefound() {
      try {
        this.loading = true;
        this.error = false;
        const { refoundRequest } = await TICKET.requestRefound(this.ticket.id, {
          reason: this.reason,
        });

        if (refoundRequest.closed) {
          this.refoundInfo.status = "REFUND_APPROVED";
        } else {
          this.refoundInfo.status = "REFUND_REQUESTED";
        }
      } catch (e) {
        this.error = e.message || "Erro ao carregar informações do ingresso";
      } finally {
        this.loading = false;
      }
    },
    open(ticket) {
      this.refoundInfo = null;
      this.ticket = ticket;
      this.reason = "";
      this.dialog = true;
      this.getRefoundInfo(ticket.id);
    },
    close() {
      if (this.loading) return;
      this.dialog = false;
      this.error = false;
      this.$emit("close");
      this.$nextTick(() => {
        this.error = false;
      });
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    currStatus() {
      return this.refoundInfo ? this.statusDic[this.refoundInfo.status] : null;
    },
  },
  mounted() {
    this.$parent.$on("refound-ticket", this.open);
  },
};
</script>

<style></style>
