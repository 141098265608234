var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"500px","overlay-opacity":"0.9","persistent":_vm.loading},on:{"click:outside":_vm.close},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(!_vm.refoundInfo)?_c('v-card',{staticClass:"pa-4"},[_c('div',{staticClass:"d-flex align-center justify-center pa-8"},[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1),(_vm.error)?_c('v-alert',{staticClass:"mb-0",attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),_c('v-card-actions',[_c('v-btn',{staticClass:"ml-0",attrs:{"color":"primary","text":"","block":""},on:{"click":_vm.close}},[_vm._v(" Fechar ")])],1)],1):[(['error', 'success', 'pending'].includes(_vm.currStatus?.type))?_c('v-card',[_c('v-card-text',[_c('div',{staticClass:"text-center pt-8"},[_c('v-avatar',{staticClass:"mb-4",attrs:{"size":"60","color":`${
              _vm.currStatus?.type == 'pending' ? 'warning' : _vm.currStatus?.type
            } lighten-4`}},[_c('v-icon',{attrs:{"x-large":"","color":`${
                _vm.currStatus?.type == 'pending' ? 'warning' : _vm.currStatus?.type
              } darken-4`}},[_vm._v(" "+_vm._s(_vm.currStatus?.type == "pending" ? "mdi-cash-clock" : _vm.currStatus?.type == "error" ? "mdi-alert" : "mdi-check")+" ")])],1),_c('h3',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.currStatus.title))]),_c('p',[_vm._v(_vm._s(_vm.currStatus.message))]),(_vm.refoundInfo?.refoundRequest?.answer)?_c('v-card',{staticClass:"pa-3 text-justify mt-4",attrs:{"outlined":""}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Resposta:")]),_vm._l((_vm.refoundInfo.refoundRequest.answer.split(
                '\n'
              )),function(line,i){return _c('p',{key:i,staticClass:"mb-2"},[_vm._v(" "+_vm._s(line)+" ")])})],2):_vm._e()],1)]),_c('v-card-actions',{staticClass:"d-flex flex-column"},[_c('v-btn',{staticClass:"ml-0",attrs:{"color":"primary","text":"","block":""},on:{"click":_vm.close}},[_vm._v(" Fechar ")])],1)],1):_vm._e(),(_vm.currStatus.type == 'canRefound')?_c('v-card',[_c('v-card-title',[_vm._v("Solicitar Reembolso")]),_c('v-card-text',[_c('v-form',{on:{"submit":function($event){$event.stopPropagation();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-textarea',{attrs:{"label":"Qual motivo do reembolso?","disabled":_vm.loading,"outlined":"","rules":[
              (v) => !!v || 'Por favor, informe o motivo do reembolso',
              (v) =>
                v.length <= 200 ||
                'O motivo do reembolso deve ter no máximo 200 caracteres',
              (v) =>
                v.length >= 10 || 'Descreva melhor o motivo do reembolso',
              (v) =>
                (v && v.split(' ').length >= 3) ||
                'Descreva melhor o motivo do reembolso',
            ],"rows":3,"counter":200,"placeholder":"Descreva o motivo do reembolso"},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}}),_c('v-alert',{attrs:{"type":"info","text":""}},[_c('b',[_vm._v(" A solicitação de reembolso será enviada para a organização do evento, ")]),_vm._v(" que irá analisar e entrar em contato com você. Quando o seu pagamento for reembolsado "),_c('b',[_vm._v("avisaremos você por WhatsApp")]),_vm._v(". ")]),_c('v-checkbox',{attrs:{"disabled":_vm.loading,"rules":[(v) => v || 'Confirme que deseja o reembolso'],"label":"Confirmo que desejo solicitar o reembolso e que não será possível reverter essa ação."},model:{value:(_vm.refoundConfirm),callback:function ($$v) {_vm.refoundConfirm=$$v},expression:"refoundConfirm"}})],1),(_vm.error)?_c('v-alert',{staticClass:"mb-0",attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()],1),_c('v-card-actions',{staticClass:"d-flex flex-column"},[_c('v-btn',{staticClass:"ml-0",attrs:{"color":"warning","disabled":!_vm.valid,"block":"","loading":_vm.loading},on:{"click":_vm.requestRefound}},[_vm._v(" Solicitar Reembolso ")]),_c('v-btn',{staticClass:"ml-0",attrs:{"color":"primary","disabled":_vm.loading,"text":"","block":""},on:{"click":_vm.close}},[_vm._v(" Fechar ")])],1)],1):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }