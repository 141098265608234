<template>
  <div class="d-flex justify-center mt-8">
    <v-card
      ref="card"
      elevation="0"
      class="w-full rounded-xl"
      style="max-width: 600px"
    >
      <div class="mb-6">
        <div style="position: relative">
          <v-img
            width="100%"
            class="rounded-t-xl rounded-b-0"
            :src="party?.cover"
            :aspect-ratio="16 / 9"
          >
            <template v-slot:placeholder>
              <v-card elevation="0" class="h-full">
                <v-skeleton-loader
                  type="image"
                  class="rounded-t-lg h-100"
                  height="100%"
                />
              </v-card>
            </template>
          </v-img>
        </div>
        <HolographicLine
          v-if="!isNotPayed && ticket?.Payment.status == 'succeeded'"
          style="height: 35px; width: 100%"
          backgroundColor="#f1ed68"
        />
        <v-card-text class="d-flex flex-column" style="gap: 0.5rem">
          <v-card outlined class="pa-4" rounded="lg">
            <div v-if="loading">
              <v-skeleton-loader
                height="20"
                width="160"
                type="heading"
                class="mb-1"
              />
              <v-skeleton-loader
                height="25"
                width="130"
                type="heading"
                class="mb-1"
              />
            </div>
            <div v-else @click="goToParty" style="cursor: pointer">
              <div class="d-flex align-center justify-space-between">
                <p
                  class="mb-0 text-overline font-weight-bold"
                  style="line-height: 1em"
                >
                  {{ ticket?.TicketBlock.TicketGroup.name }} •
                  {{ ticket?.TicketBlock.name }}
                </p>
                <v-chip small x-small v-if="partyTickets.length > 1">
                  <v-icon x-small left>mdi-ticket</v-icon>
                  {{ currentTicketIndex + 1 }}/{{ partyTickets.length }}
                </v-chip>
              </div>
              <h5>
                {{ party?.name }}
              </h5>
            </div>
            <v-skeleton-loader
              v-if="loading"
              height="18"
              width="175"
              type="heading"
              class="mb-0 mt-2"
            />
            <div v-else class="d-flex align-center text-body-2">
              <v-icon small left>mdi-calendar</v-icon>
              <span>{{
                party
                  | startEndDate("date", "endDate", " - ", {
                    month: " [de] MMM",
                  })
              }}</span>
            </div>

            <description-wrap
              holder="ticket-block-description"
              :description="ticket?.TicketBlock.TicketGroup.description"
            />
            <v-row dense v-if="loading" class="mx-0 mt-2">
              <v-col cols="6" v-for="i in 2" :key="i">
                <v-skeleton-loader
                  type="image"
                  class="rounded h-100"
                  height="36"
                />
              </v-col>
            </v-row>
            <v-card
              v-if="
                !loading &&
                ticket?.Payment.status == 'succeeded' &&
                ticket.Table
              "
              outlined
              class="pa-3 d-flex align-center gap-2 rounded-lg"
            >
              <v-icon>mdi-table-chair</v-icon>
              <h5 class="mb-0 text-16 flex-grow-1">
                {{ ticket.Table.Group.name }} - {{ ticket.Table.name }}
              </h5>
              <v-btn small color="primary" @click="tableMapView">
                Ver no mapa
              </v-btn>
            </v-card>

            <v-row class="mx-0">
              <v-col v-if="isNotPayed">
                <v-btn
                  color="warning"
                  @click="pay"
                  block
                  class="mt-4"
                  :disabled="loading"
                >
                  Pagar
                </v-btn>
              </v-col>
              <template
                v-else-if="ticket?.TicketBlock.TicketGroup.Party.active"
              >
                <template
                  v-if="ticket?.TicketBlock?.TicketGroup?.requireBiometry"
                >
                  <v-alert
                    v-if="hasFace"
                    color="info"
                    class="mt-2"
                    text
                    dense
                    border="left"
                  >
                    <v-icon
                      class="float-right"
                      small
                      @click="captureBiometry(true)"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                    <div class="d-flex align-start gap-1 mb-1">
                      <v-icon color="info" left class="mt-1">
                        mdi-face-recognition
                      </v-icon>
                      <span class="text-14">
                        Este evento usa reconhecimento facial para entrada. Está
                        tudo certo com o seu cadastro, basta ir ao evento
                      </span>
                    </div>
                  </v-alert>
                  <v-alert
                    v-else
                    color="warning"
                    class="mt-2"
                    dense
                    border="left"
                  >
                    <v-icon
                      class="float-right"
                      color="white"
                      small
                      @click="captureBiometry(true)"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                    <div class="d-flex align-start gap-1 mb-1">
                      <v-icon color="white" left class="mt-1">
                        mdi-face-recognition
                      </v-icon>
                      <span class="text-14 white--text">
                        Este evento exige o cadastro facial para entrada. Você
                        ainda não cadastrou sua biometria.
                      </span>
                    </div>
                    <v-btn small block depressed @click="captureBiometry()">
                      Cadastrar biometria
                    </v-btn>
                  </v-alert>
                </template>

                <v-btn
                  block
                  color="primary"
                  class="mt-2"
                  v-if="ticket?.Payment.status == 'succeeded'"
                  @click="openQrCode"
                  :disabled="loadingExport"
                >
                  Ver Ingresso
                  <v-icon right>mdi-qrcode</v-icon>
                </v-btn>
                <v-btn
                  v-if="ticket?.Payment.status == 'succeeded'"
                  block
                  color="primary"
                  class="mt-2"
                  @click="exportPDF"
                  text
                  :loading="loadingExport"
                >
                  Exportar Ingresso
                  <v-icon right>mdi-invoice-export-outline</v-icon>
                </v-btn>
                <v-btn
                  v-if="canTransfer"
                  block
                  text
                  color="primary"
                  class="mt-2"
                  @click.prevent="transfer"
                  :disabled="loadingExport"
                >
                  Transferir
                  <v-icon right>mdi-account-arrow-right</v-icon>
                </v-btn>
              </template>
            </v-row>
          </v-card>

          <v-alert
            v-if="canRating && !rating"
            @click="canRating && rate"
            type="warning"
            class="mt-3"
            icon="mdi-star"
          >
            <div class="d-flex align-center gap-2 white--text">
              <span>
                Você participou desse evento! Avalie-o para ajudar a melhorar os
                próximos.
                <p class="mb-0" v-if="disableRating">
                  <small>{{ disableRating }}</small>
                </p>
              </span>
              <v-spacer />
              <v-btn small @click="rate" :disabled="loading || !!disableRating">
                Avaliar
              </v-btn>
            </div>
          </v-alert>
          <v-alert class="mt-3" v-else-if="!!rating" type="success">
            Obrigado por avaliar o evento
          </v-alert>

          <v-alert v-if="responseType === 'cache'" text type="info">
            Não foi possível atualizar o ingresso, quando a conexão com a
            internet for restabelecida, ele será atualizado.
          </v-alert>

          <pwa-install v-else-if="!loading">
            <template slot="message">
              <b>Tenha acesso ao seu ingresso mesmo sem internet.</b>
              <br />
              <span>Instale o aplicativo e tenha acesso offline.</span>
            </template>
            <template slot="success-message">
              <b>Instalado com sucesso!</b>
              <br />
              <span>
                Você já pode acessar seu ingresso mesmo sem internet.
              </span>
            </template>
          </pwa-install>
          <whatsapp-group-join
            v-if="canWhatsappGroupView"
            :success="whatsappGroup.inGroup"
            :ticket="ticket"
          />

          <v-alert
            v-if="party?.requireDocuments"
            type="info"
            text
            dense
            class="mb-0"
            border="left"
          >
            <b>Documentos necessários</b><br />
            {{ ticket?.TicketBlock.TicketGroup.Party.requireDocuments }}
          </v-alert>

          <party-address v-if="canAddressView" :party="party" :loading="loading" />

          <div v-if="!isNotPayed && !loading && responseType === 'online'">
            <div class="d-flex justify-space-between align-center">
              <h5 class="">Pagamento</h5>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small v-bind="attrs" v-on="on">
                    <v-icon small>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="refound" link>
                    <v-list-item-title>Reembolso</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>

            <v-alert type="info" dense text v-if="ticket.TicketTransfer">
              Esse ingresso foi transferido de outro usuário, o pagamento não
              foi realizado por você.
            </v-alert>

            <payment-status
              @click="goToPayment"
              v-else-if="ticket?.Payment"
              class="mt-2"
              :payment="ticket?.Payment"
            />

            <!-- AttractionsList -->
            <attractions-list
              v-if="party?.Period || party?.Attraction"
              ref="attractions"
              :attractions="party.Attraction || []"
              :periods="party.Period"
              :tickets="[ticket]"
            />
          </div>
        </v-card-text>

        <p class="text-center" v-if="!loading">
          <v-icon small left>mdi-wifi-off</v-icon>
          <span class="text-caption">Ingresso disponível sem internet</span>
        </p>
      </div>

      <ticket-dots bottom />
    </v-card>

    <qr-code-view
      @prev="previousTicket"
      @next="nextTicket"
      :party="party"
      :tickets="partyTickets"
      :ticket="ticket"
      :currentIndex="currentTicketIndex"
    />
    <export-view
      v-if="party"
      :party="party"
      :tickets="partyTickets || [ticket]"
      ref="exportPdf"
      name="exportPdf"
    />
    <transfer-ticket />
    <party-rating @rated="getTicket" />
    <ticket-refound />
    <my-table-view v-if="ticket?.Table" :ticket="ticket" :party="party" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

import QrCodeView from "@/components/app/modals/QrCodeView.vue";
import ExportView from "@/components/app/modals/ExportView.vue";
import TransferTicket from "@/components/app/modals/TransferTicket.vue";
import TicketRefound from "@/components/app/modals/TicketRefound.vue";
import PartyRating from "@/components/app/modals/PartyRating.vue";
import MyTableView from "@/components/app/modals/MyTableView.vue";
import PaymentStatus from "../../../components/global/PaymentStatus.vue";
import DescriptionWrap from "../../../components/global/DescriptionWrap.vue";
import PartyAddress from "../../../components/shop/PartyAddress.vue";
import TicketDots from "../../../components/global/TicketDots.vue";
import WhatsappGroupJoin from "../../../components/app/WhatsappGroupJoin.vue";
import PwaInstall from "../../../components/global/PwaInstall.vue";
import HolographicLine from "../../../components/global/HolographicLine.vue";
import AttractionsList from "../../../components/global/party/AttractionsList.vue";

export default {
  metaInfo() {
    return {
      title:
        "Ingresso " +
        (this.ticket ? this.ticket?.TicketBlock.TicketGroup.Party.name : ""),
    };
  },
  components: {
    QrCodeView,
    TransferTicket,
    PartyRating,
    PaymentStatus,
    DescriptionWrap,
    PartyAddress,
    TicketDots,
    WhatsappGroupJoin,
    PwaInstall,
    HolographicLine,
    TicketRefound,
    MyTableView,
    AttractionsList,
    ExportView,
  },
  data: () => ({
    loading: true,
    ticket: null,
    rating: null,
    whatsappGroup: null,
    responseType: "online",
    loadingExport: false,
  }),
  watch: {
    isOnline(value) {
      if (value) this.getTicket();
    },
  },
  methods: {
    ...mapActions("app", ["getTicketById", "updateTickets"]),
    goToPayment() {
      this.$router.push({
        name: "app.payment.details",
        params: { id: this.ticket.Payment.id },
      });
    },
    async exportPDF() {
      try {
        this.loadingExport = true;
        if (
          this.$refs.exportPdf &&
          typeof this.$refs.exportPdf.downloadPDF === "function"
        ) {
          await this.$refs.exportPdf.downloadPDF();
        } else {
          console.error("Método downloadPDF não encontrado");
        }
      } catch (error) {
        console.error("Erro ao exportar PDF:", error);
      } finally {
        this.loadingExport = false;
      }
    },
    goToParty() {
      this.$router.push({
        name: "shop.party",
        params: {
          orgSlug: this.ticket?.TicketBlock.TicketGroup.Party.Organization.slug,
          partyId:
            this.ticket?.TicketBlock.TicketGroup.Party.slug ||
            this.ticket?.TicketBlock.TicketGroup.Party.id,
        },
      });
    },
    pay() {
      this.$router.push({
        name: "app.payment.details",
        params: { id: this.ticket.paymentId },
        hash: "#pay",
      });
    },
    openQrCode() {
      this.$emit("openQrCode");
    },
    tableMapView() {
      this.$emit("tableMapView", this.ticket);
    },
    transfer() {
      this.$emit("transfer", this.ticket);
    },
    rate() {
      this.$emit("rating", {
        party: this.party,
        succeeded: !!this.rating,
      });
    },
    refound() {
      this.$emit("refound-ticket", this.ticket);
    },
    async getTicket(loading) {
      try {
        this.loading = loading ?? true;
        const ticketId = this.$route.params.id;
        const { type, ...response } = await this.getTicketById(ticketId);
        if (!response.ticket) throw new Error("Ticket não encontrado");

        const platform =
          response.ticket.TicketBlock.TicketGroup.Party.Organization.Platform;
        if (platform) {
          console.log("Redirecting to platform");
          const { domain } =
            response.ticket.TicketBlock.TicketGroup.Party.Organization.Platform;
          const path = this.$route.path;
          const url = `//${domain}${path}`;
          window.location.replace(url);
          return;
        }

        if (response.ticket?.id !== this.$route.params.id) return;

        this.responseType = type;
        this.ticket = response.ticket;
        this.rating = response.rating;
        this.whatsappGroup = response.whatsappGroup;
        this.loading = false;
        // this.$root.$emit("setImgBg", this.party?.cover);
      } catch (error) {
        console.log(error);
        this.$router.push({ name: "app.ticket" });
      }
    },
    nextTicket() {
      const nextTicketIndex = this.currentTicketIndex + 1;
      const nextTicket =
        this.partyTickets[nextTicketIndex % this.partyTickets.length];
      if (nextTicket) {
        this.ticket = nextTicket;
        this.$router.replace({
          name: "app.ticket.details",
          params: { id: nextTicket.id },
        });
        this.getTicket(false);
      }
    },
    previousTicket() {
      const previousTicketIndex = this.currentTicketIndex - 1;
      const previousTicket =
        this.partyTickets[
          (previousTicketIndex + this.partyTickets.length) %
            this.partyTickets.length
        ];
      if (previousTicket) {
        this.ticket = previousTicket;
        this.$router.replace({
          name: "app.ticket.details",
          params: { id: previousTicket.id },
        });
        this.getTicket(false);
      }
    },
    captureBiometry(help = false) {
      this.$router.push({
        name: "face.capture",
        query: { help: help || undefined, redirect: this.$route.fullPath },
      });
    },
  },
  computed: {
    ...mapGetters("app", ["tickets"]),
    ...mapGetters("auth", ["user"]),
    hasFace() {
      return this.user?.Biometrics.find((b) => b.type === "FACE");
    },
    partyTickets() {
      const partyId = this.party?.id;
      return this.tickets.filter(
        (t) =>
          t.TicketBlock.TicketGroup.Party.id == partyId &&
          t.Payment.status === "succeeded"
      );
    },
    currentTicketIndex() {
      return this.partyTickets.findIndex((t) => t.id == this.ticket?.id);
    },
    party() {
      return this.ticket?.TicketBlock?.TicketGroup?.Party;
    },
    endDayIsSameDay() {
      return moment(this.ticket?.TicketBlock.TicketGroup.Party.endDate).isSame(
        moment(this.ticket?.TicketBlock.TicketGroup.Party.date),
        "day"
      );
    },
    canRating() {
      if (this.responseType !== "online") return false;
      return this.ticket && this.ticket.TicketEntry.length > 0;
    },
    disableRating() {
      if (!this.canRating) return true;
      if (
        moment(this.ticket?.TicketBlock.TicketGroup.Party.endDate).isAfter(
          moment()
        )
      )
        return "Aguarde o fim do evento para avaliar";
      return false;
    },
    canTransfer() {
      if (this.responseType !== "online") return false;
      return (
        this.ticket?.Payment.status == "succeeded" &&
        this.ticket?.TicketBlock.allowTransfer
      );
    },
    canAddressView() {
      if (this.responseType !== "online") return false;
      return !!this.party?.Address;
    },
    canWhatsappGroupView() {
      if (this.responseType !== "online") return false;
      return this.whatsappGroup?.canJoin || this.whatsappGroup?.inGroup;
    },
    isNotPayed() {
      return (
        [
          "pending",
          "requires_payment_method",
          "requires_confirmation",
          "requires_action",
          "processing",
          "requires_capture",
          "rejected",
        ].includes(this.ticket?.Payment.status) &&
        moment(this.ticket?.Payment.validity).isAfter(moment())
      );
    },
  },
  async mounted() {
    await this.getTicket();
    if (this.$route.hash == "#pay" && this.isNotPayed) {
      this.$router.replace({
        name: "app.ticket.details",
        params: { id: this.ticket.id },
      });
      this.pay();
    }
    if (
      this.$route.query.redirect_status === "succeeded" &&
      this.ticket?.Payment.status == "succeeded"
    ) {
      this.$confetti.start();
      await new Promise((r) => setTimeout(r, 1500));
      this.$confetti.stop();
      await new Promise((r) => setTimeout(r, 3500));
    }
    this.updateTickets();
  },
};
</script>
